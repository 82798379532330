import {Component, useEffect, useState} from "react";

import {
    Input,
    DatePicker,
    Card,
    Image,
    Flex,
    Pagination,
    Row,
    Typography,
    Space,
    Progress,
    message,
    Collapse,
    Select,

    Popconfirm, Button, Divider, Table, Popover
} from "antd";
import Apiurl from "../ConstURL";
import {format} from 'date-fns'
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import {
    CheckOutlined, CloseOutlined,
    FileExcelOutlined, MinusOutlined, DeleteOutlined, SearchOutlined,
} from '@ant-design/icons';

import Header from "../additional/header";

import Search from "antd/es/input/Search";
import moment from "moment";

const {Text, Link} = Typography;
const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
};
const disabledRangeTime = (_, type) => {
    if (type === 'start') {
        return {
            disabledHours: () => range(0, 60).splice(4, 20),
            disabledMinutes: () => range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    }
    return {
        disabledHours: () => range(0, 60).splice(20, 4),
        disabledMinutes: () => range(0, 31),
        disabledSeconds: () => [55, 56],
    };
};
const {RangePicker} = DatePicker;
export default function Mainpage() {

    const [Loading, setLoading] = useState(false);
    const [FindedList, setFindedList] = useState([]);
    const [tasks, settasks] = useState([])
    const [taskscount, settaskscount] = useState(0)


    const [filter_search_text, Set_filter_search_text] = useState("")


    // function UpdateFiltersTickets(){
    //     fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
    //         token: localStorage.getItem("token"),
    //         skip: (1 - 1) * 10,
    //         limit: 10,
    //         filter_workerid: filter_workerid,
    //         filter_chatid: filter_chatid,
    //         filter_insideid: filter_insideid,
    //         filter_type: filter_type,
    //         filter_status: filter_status,
    //         filter_search_text: filter_search_text,
    //         from_time: time_from,
    //         to_time: time_to,
    //         time_offset: new Date().getTimezoneOffset().toString(),
    //
    //     }))
    //         .then(response => {
    //             return response.json()
    //         })
    //         .then(data => {
    //             console.log(data.Allcount)
    //             settaskscount(data.Allcount)
    //             settasks(data.Data)
    //         })
    // }
    const onChangeFilterfilter_insearchtext = (text) => {
        text = text.target.value
        console.log(text)
        // setTimeout(() => {  console.log("World!"); }, 1000);
        if (text === "") {
            Set_filter_search_text("")
        } else {
            Set_filter_search_text(text)
        }



        //UpdateFiltersTickets();

    };


    const onSearch = () => {
        setLoading(true)
        fetch(Apiurl + "/gmail/info/sku?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skuid: filter_search_text,


        }))
            .then(response => {
                return response.json()
            })
            .then(data => {

                var datatoset=[]
                data.Data.forEach((item) => {

                    item.AllItems.forEach((item2) => {
                        datatoset.push({
                            "id":item.OrderNumber,
                            "order_date": item.OrderDate,
                            "sku":<Popover content={<Image src={item2.Url}/>} trigger="hover">{<div style={{color:"#ae3cff"}}>{item2.ZoroSKU}</div>}</Popover>,
                            "title":item2.Title,
                            "quantity":item2.Quantity,
                            "price":item2.Price
                        })
                    })

                })
                setLoading(false)
                setFindedList(datatoset)
            })

    };

    const columns = [
        {
            title: 'Order number',
            dataIndex: 'id',
            key: 'id',

        },
        {
            title: 'Order date',
            dataIndex: 'order_date',
            key: 'order_date',
            sorter:(a, b) => moment(a.order_date).unix() - moment(b.order_date).unix(),
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
        },{
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },{
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },{
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },

    ];


    return (<div>
            <Header/>
            <Flex gap={"large"} justify={"center"} style={{paddingTop: "30px"}}>

                <Input
                    style={{width: 300}}
                    placeholder={"SKU Id"}
                    onChange={onChangeFilterfilter_insearchtext}
                >
                </Input>


                <Button type={"primary"} icon={<SearchOutlined/>}
                        onClick={event => onSearch()} >
                    Поиск
                </Button>
            </Flex>

            <Flex vertical={false} justify={"center"} style={{paddingTop: "25px"}}>
                <Table
                    style={{paddingTop:"25px"}}
                    loading={Loading}
                    columns={columns}
                    pagination={{
                        showSizeChanger: true,
                        showTotal: () => `Найдено ${FindedList.length}`,

                        position: "Center",
                        pageSizeOptions: [10,20,30,40,50,100]
                    }}
                    dataSource={FindedList}
                />




            </Flex>


        </div>
    );

}