import React, {Component, useEffect} from 'react';
import  Apiurl from '../ConstURL'
import {Button, Space, Form, Input, Row, message, Card} from 'antd';
import {useNavigate} from "react-router-dom";
import {useAuth} from "./Context";
import {RollbackOutlined} from "@ant-design/icons";


// const handleSubmit = event => {
//
//     event.preventDefault();
//
//     // 👇️ redirect to /contacts
//     navigate('/contacts');
// };


export default function  Authpage (){
    const Navigate = useNavigate()

    const {user, loginUser} = useAuth()

    // render() {
    useEffect(()=>{
        try {
            if(user['Status']){
                Navigate("/")
            }
        }catch (err){

        }


    },[]);
    const onFinish = (values) => {
        console.log(values)
        fetch(Apiurl+"/user/auth?username="+values.username+"&pass="+values.password)
            .then(response => {
                return response.json()

            }).then(data => {
                console.log(data)
                if(data['Status']){
                    localStorage.setItem("token",data['Data'])
                    localStorage.setItem("userid",data['Id'])
                    localStorage.setItem("username",values.username)
                    if (data['Admin']){
                        localStorage.setItem("admin","yes")
                    }else {
                        localStorage.setItem("admin","no")
                    }



                    message.success("Авторизация прошла успешно!")
                    loginUser()
                    localStorage.setItem("currentpage","main")
                    Navigate("main")

                    console.log('Success:', values.username);
                }else {
                    message.error("Неверный логин или пароль!")
                }
            }

        )

        //window.alert("Авторизация прошла успешно!")
    };

    const onFinishFailed = (errorInfo) => {
        //console.log('Failed:', errorInfo);
    };
    return(
        <Row type="flex" justify="center" align="middle" >
            <Card type="flex" title={"Авторизация"} justify="center" align="middle" style={{margin:"20px", minWidth:"300"}}>
                {/*<Space direction="horizontal" style={{width: '100%', justifyContent: 'center',paddingTop: '25px'}}>*/}
                <Form

                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        verticalAlign: 'middle',
                        maxWidth: "70vw",
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Логин"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Введите ваш логин!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Пароль"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Введите ваш пароль!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>



                    <Form.Item

                    >
                        <Button type="primary" htmlType="submit">
                            Авторизоваться
                        </Button>
                    </Form.Item>
                </Form>
                {/*</Space>*/}
            </Card>
        </Row>
    );
    // }
}