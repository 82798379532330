import {createContext, useState, useEffect, useContext} from "react";
import { Outlet, Navigate } from 'react-router-dom'

import {useNavigate} from "react-router-dom";

import Apiurl from "../ConstURL";

const AuthContext = createContext()


export const AuthProvider = ({children}) => {
    const navigate = useNavigate()
    var token = localStorage.getItem("token")
    const Check = fetch(Apiurl + "/user/info?token=" + token)
        .then((response) => response.json()
        ).then((json) => {
            return json
        })

    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)

    useEffect(() => {
        //setLoading(false)
        checkUserStatus()

    }, [])

    const loginUser = async (userInfo) => {
        setLoading(true)

        console.log('userInfo', userInfo)
        var token = localStorage.getItem("token")
        console.log(token)
        try {
            //let response = await account.createEmailSession(userInfo.email, userInfo.password)
            const Check1 = await fetch(Apiurl + "/user/info?token=" + token)
                .then((response) => response.json()
                ).then((json) => {
                    return json
                })

            let accountDetails = Check1;
            console.log(Check1)
            if (accountDetails["Status"]) {
                setUser(accountDetails)

            } else {
                console.log("logout")
            }

        } catch (error) {
            console.error(error)
        }
        setLoading(false)

    }

    const logoutUser = async () => {

        setUser(null)
    }


    const checkUserStatus = async () => {
        try {

            let accountDetails = await Check;
            if (accountDetails['Status']) {
                localStorage.setItem("authed", "yes")
                if (accountDetails['Data']['Admin']) {
                    localStorage.setItem("admin", "yes")
                }else {
                    localStorage.setItem("admin", "false")
                }
                setUser(accountDetails)
            } else {
                localStorage.setItem("authed", "no")
                console.log("logout")
            }
        } catch (error) {

        }
        setLoading(false)
    }

    const contextData = {
        user,
        loginUser,
        logoutUser,
    }

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? <p>Loading...</p> : children}
        </AuthContext.Provider>
    )
}

//Custom Hook
export const useAuth = () => {
    return useContext(AuthContext)
}

export const PrivateRoutes1 = () => {
    const {user} = useAuth()
    return user ? <Outlet/> : <Navigate to="/login"/>
}

export default AuthContext;