import React, {useEffect, useState} from 'react';
import { LogoutOutlined,PlusCircleOutlined,SettingOutlined,BarsOutlined,HomeOutlined, UserOutlined, SolutionOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import {useNavigate} from "react-router-dom";
import Apiurl from "../ConstURL";

const itemsAdmin = [
    {
        label: 'Главная',
        key: 'main',
        icon: <HomeOutlined/>,
    }
    ,{
        label: 'Выйти из аккаунта',
        key: 'logout',
        icon: <LogoutOutlined />,
        disabled: false,
    },

];

const itemsSimple = [
    {
        label: 'Главная',
        key: 'main',
        icon: <HomeOutlined/>,
    },
    {
        label: 'Выйти из аккаунта',
        key: 'logout',
        icon: <LogoutOutlined />,
        disabled: false,
    },

];

export default function Header (){



    const navigate = useNavigate()
    const [current, setCurrent] = useState(localStorage.getItem("currentpage") ==="" ? "main" : localStorage.getItem("currentpage"));

    useEffect(() => {

        // if(localStorage.getItem("currentpage")!==""){
        //     setCurrent(localStorage.getItem("currentpage"));
        //
        // }

    }, [])


    const onClick = (e) => {
        if (e.key==="logout"){
            setCurrent(e.key);
            localStorage.setItem("currentpage", e.key)
            localStorage.setItem("token", "")
            window.location.replace("/login");
        }else {
            setCurrent(e.key);
            localStorage.setItem("currentpage", e.key)
            navigate("/" + e.key)
        }

    };
    return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={localStorage.getItem("admin")==="yes" ? itemsAdmin : itemsSimple}/>;

}