import logo from './logo.svg';
import {ConfigProvider, Layout, theme} from "antd";
import {AuthProvider} from "./auth/Context";
import {Header} from "antd/es/layout/layout";
import {Route, Routes, useParams} from "react-router-dom";
import PrivateRoutes1 from "./auth/privateroute";
import ruRu from 'antd/locale/ru_RU';
import './App.css';
import Authpage from "./auth/authconf";
import Mainpage from "./pages/mainfind";

function App() {
  return (
      <ConfigProvider
          locale={ruRu}
          theme={{

            algorithm: theme.darkAlgorithm,
            "token": {

              "colorPrimary": "#a017ff",
              "colorInfo": "#a017ff",
              "fontSize": 16
            },

          }}
      >
        <Layout style={{minHeight: "100vh"}}>
          <AuthProvider>
            <Routes>
              <Route path="/login" element={<Authpage/>}/>

              <Route element={<PrivateRoutes1/>}>
                <Route exact path='/*' element={<Mainpage/>}/>


                {/*<Route path='/transport' element={<Transport/>}/>*/}
              </Route>
            </Routes>

          </AuthProvider>
        </Layout>
      </ConfigProvider>
  );
}

export default App;
