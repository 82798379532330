import {useAuth} from "./Context";
import { Outlet, Navigate } from 'react-router-dom'
const PrivateRoutes1 = () => {
    const {user} = useAuth()
    return user ? <Outlet/> : <Navigate to="/login"/>
}





export default PrivateRoutes1